import React, { Fragment, FunctionComponent, memo, useState } from 'react'
import dynamic from 'next/dynamic'
import { LeafletMapProps } from '@bbx/search-journey/common/components/Map/LeafletMap'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { Box } from '@wh-components/core/Box/Box'
import PinIcon from '@wh-components/icons/Pin'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { useUserAgent } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import BulbIcon from '@wh-components/icons/Bulb'
import { HTMLNativeModal } from '@wh-components/core/Modal/HTMLNativeModal'
import { theme } from '@wh-components/core/theme'
import { css, keyframes } from 'styled-components'
import { Spinner } from '@wh-components/core/Spinner/Spinner'
import { Button } from '@wh-components/core/Button/Button'
import { Text } from '@wh-components/core/Text/Text'
import type { ContactDetailAddress } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { Card } from '@wh-components/core/Card/Card'
import ArrowRightThick from '@wh-components/icons/ArrowRightThick'
import sunIcon from '@bbx/static_hashed/img/adDetail/icon_shadowmap_sun.svg'

const LeafletMap = dynamic<LeafletMapProps>(
    () => import('@bbx/search-journey/common/components/Map/LeafletMap').then((m) => m.LeafletMap),
    {
        ssr: false,
        loading: () => <Box height="100%" width="100%" borderRadius="m" backgroundColor="palette.polarbear" />,
    },
)

interface AdDetailMapProps extends LeafletMapProps {
    adId: string
    taggingData: TaggingData
    showShadowmapButton?: boolean
    address?: ContactDetailAddress
}

export const AdDetailMap: FunctionComponent<AdDetailMapProps> = memo(({ adId, taggingData, showShadowmapButton, address, ...props }) => {
    const isVeryOldChrome = useIsVeryOldChrome()
    const [isShadwomapModalOpen, setIsShadowmapModalOpen] = useState(false)
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const [iframeHasBeenClickedOnOnce, setIframeHasBeenClickedOnOnce] = useState(false)
    // this is the production URL for shadowmap
    // it will not work locally, due to missing security headers
    // if you want to test it locally, you need to use: https://staging-app.shadowmap.org/?config=willhaben-immoaddetail
    const shadowmapUrl =
        showShadowmapButton && address?.street && props.center
            ? `https://willhaben-immoaddetail.poweredby.shadowmap.org/?lat=${props.center[0]}&lng=${props.center[1]}&zoom=18.5`
            : null

    const handleShadowMapClick = () => {
        setIframeHasBeenClickedOnOnce(true)
        callActionEvent('estate_addetail_shadowmap', taggingData)
        setIsShadowmapModalOpen(true)
    }

    const controls: LeafletMapProps['controls'] = [
        {
            position: 'bottomright',
            element: (
                <ServerRoutingAnchorLink
                    type="button"
                    href={`/iad/mapobject?adId=${adId}`}
                    Icon={PinIcon}
                    size="small"
                    color="primary"
                    variant="outline"
                    testId="open-fullscreen-map"
                    onClick={() => callActionEvent('addetail_map_click', taggingData)}
                >
                    Karte öffnen
                </ServerRoutingAnchorLink>
            ),
        },
    ]

    if (shadowmapUrl) {
        controls.push({
            position: 'bottomleft',
            element: (
                <Button
                    type="button"
                    Icon={BulbIcon}
                    size="small"
                    color="primary"
                    variant="outline"
                    testId="open-shadowmap"
                    onClick={handleShadowMapClick}
                >
                    Lichtverhältnisse
                </Button>
            ),
        })
    }

    return isVeryOldChrome ? null : (
        <Fragment>
            <Box height={props.height}>
                <LeafletMap borderRadius="m" overflow="hidden" {...props} controls={controls} />
            </Box>
            {showShadowmapButton && (
                <Card
                    display="flex"
                    alignItems="center"
                    gap="m"
                    backgroundColor="palette.special.lemon20"
                    border="none"
                    onClick={handleShadowMapClick}
                    role="button"
                    css={css`
                        cursor: pointer;
                    `}
                >
                    <Box minWidth="40px" minHeight="40px" backgroundImage={`url(${sunIcon})`} />
                    <Box>
                        <Text fontWeight="bold" as="p">
                            Jetzt Lichtverhältnisse visualisieren!
                        </Text>
                        <Text as="p">Entdecke wie die Sonne durch die Fenster deines Wohntraums lacht.</Text>
                    </Box>
                    <ArrowRightThick marginLeft="auto" />
                </Card>
            )}
            {iframeHasBeenClickedOnOnce && shadowmapUrl && address?.street && (
                <HTMLNativeModal
                    isOpen={isShadwomapModalOpen}
                    setIsOpen={setIsShadowmapModalOpen}
                    fullScreen={{ phone: true, tablet: false }}
                    header={`Lichtverhältnisse für ${address.street}`}
                    css={css`
                        max-width: 100dvw;
                        max-height: 100dvhw;
                        width: 100dvw;
                        height: 110dvh;

                        @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}px) {
                            width: 90svw;
                            height: 90svh;
                        }
                    `}
                >
                    {!iframeLoaded && (
                        <Box
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            testId="shadowmap-spinner"
                            css={css`
                                /* let spinner appear a bit later to avoid it flashing only for a fraction of a second in case the iframe loads fast */
                                animation: ${fadein} 0.25s ease-in 1s both;
                            `}
                        >
                            <Spinner />
                        </Box>
                    )}
                    <iframe
                        src={shadowmapUrl}
                        title="Shadowmap"
                        allow="autoplay; fullscreen"
                        data-testid="shadowmap-iframe-tenant-overview"
                        onLoad={() => {
                            setIframeLoaded(true)
                        }}
                        css={css`
                            width: 100%;
                            height: 100%;
                            border: none;
                            /* this avoids showing the spinner over partially loaded content, since the onLoad event will hide it only shortly after content has been loaded */
                            position: relative;

                            @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}px) {
                                border-radius: ${theme.borderRadii.l}px;
                            }
                        `}
                    />
                </HTMLNativeModal>
            )}
        </Fragment>
    )
})

// Probably due to a change in Next.js (https://github.com/vercel/next.js/pull/42589),
// dynamic imports are causing issues on older, unsupported (!) Chrome browsers.
// Therefore, we avoid dynamic imports, actually do not show LeafLet maps on Ad Detail for them.
const useIsVeryOldChrome = () => {
    const parsedUA = useUserAgent().uaParserResult
    if (!parsedUA || parsedUA.browser.name?.toLowerCase() !== 'chrome' || !parsedUA.browser.version) {
        return false
    }

    const [majorString] = parsedUA.browser.version.split('.')
    const major = parseInt(majorString, 10)
    if (isNaN(major)) {
        return false
    }

    return major <= 38
}

const fadein = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`
