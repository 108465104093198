import React, { FunctionComponent } from 'react'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LayoutProps } from '@wh/common/chapter/components/Layouts/LayoutProps'

type LeaderboardAndSkyscraperLayoutProps = Omit<LayoutProps, 'showLeaderboard' | 'showSkyscraper'>

export const LeaderboardAndSkyscraperLayout: FunctionComponent<LeaderboardAndSkyscraperLayoutProps> & { appEmbedded: false } = (props) => (
    <Layout {...props} showLeaderboard={true} showSkyscraper={true} />
)

LeaderboardAndSkyscraperLayout.appEmbedded = false
