import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { TestProps } from '@wh-components/core/common'
import { Box } from '@wh-components/core/Box/Box'
import { crossBrowserBreakWordStyles, Text } from '@wh-components/core/Text/Text'
import { crossBrowserPageBreakStyles } from '@bbx/common/lib/columnLayoutPageBreakStyles'

export const PropertyList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`

interface PropertyItemProps extends TestProps, PropsWithChildren {
    title?: string
}

export const PropertyItem: FunctionComponent<PropertyItemProps> = ({ title, testId, children }) => (
    <Box
        as="li"
        display="flex"
        alignItems="top"
        marginBottom="xs"
        overflow="hidden"
        testId={testId ? `${testId}-item` : undefined}
        css={crossBrowserPageBreakStyles}
    >
        {title && (
            <Box width="45%" flexShrink={0} paddingRight={{ phone: 's', desktop: 'xs' }} testId={testId ? `${testId}-title` : undefined}>
                <Text color="palette.elephant" breakWord={true}>
                    {title}
                </Text>
            </Box>
        )}
        <Box testId={testId ? `${testId}-value` : undefined} css={crossBrowserBreakWordStyles}>
            {children}
        </Box>
    </Box>
)
