import React, { FunctionComponent } from 'react'
import { FacebookPixel } from '@wh/common/chapter/components/FacebookPixel/FacebookPixel'

interface AdDetailFacebookPixelProps {
    orgId?: number
    adId?: string
    verticalId?: number
}

// IDs provided by Pierre Kelz-Flitsch
const pixelIds: { [key: number]: string } = {
    1: '808720734561919',
    2: '696066345990667',
    3: '1718541518553518',
    4: '837976290996017',
    5: '837976290996017',
}

export const FacebookPixelAdDetailViewed: FunctionComponent<AdDetailFacebookPixelProps> = ({ adId, orgId, verticalId }) => {
    if (!verticalId) {
        return null
    }
    const pixelId = pixelIds[verticalId]
    return <FacebookPixel id={pixelId} eventName="ViewContent" content_ids={adId} content_category={String(orgId)} content_type="product" />
}

export const FacebookPixelScrollToMessaging: FunctionComponent<AdDetailFacebookPixelProps> = ({ adId, orgId, verticalId }) => {
    if (!verticalId) {
        return null
    }
    const pixelId = pixelIds[verticalId]
    return <FacebookPixel id={pixelId} eventName="AddToCart" content_ids={adId} content_category={String(orgId)} content_type="product" />
}

export const FacebookPixelContacted: FunctionComponent<AdDetailFacebookPixelProps & { price: string | undefined }> = ({
    adId,
    orgId,
    price,
    verticalId,
}) => {
    if (!verticalId) {
        return null
    }
    const pixelId = pixelIds[verticalId]
    return (
        <FacebookPixel
            id={pixelId}
            eventName="Purchase"
            content_ids={adId}
            content_category={String(orgId)}
            content_type="product"
            value={price}
            currency="EUR"
        />
    )
}
