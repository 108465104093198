import React, { Fragment, FunctionComponent, RefObject } from 'react'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { css } from 'styled-components'
import { AdvertContactDetails } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { AdvertStatus, ContactOption } from '@bbx/common/types/ad-detail/AdvertDetails'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdDetailStickyFooter } from '@bbx/search-journey/sub-domains/ad-detail/components/common/StickyFooter/AdDetailStickyFooter'
import { StickyFooterPhoneNumberButton } from './StickyFooterPhoneNumberButton'

interface CallAndContactAdDetailStickyFooterProps {
    stickyFooterVisibilityTrackingRef: RefObject<HTMLDivElement>
    advertContactDetails: AdvertContactDetails
    adId: string
    orgId?: number
    taggingData: TaggingData
    contactOption: ContactOption
    adStatus: AdvertStatus
    doFacebookAndGoogleTracking?: boolean
    verticalId?: number
    onAdvertisingStateContacted?: () => void
}

export const CallAndContactAdDetailStickyFooter: FunctionComponent<CallAndContactAdDetailStickyFooterProps> = ({
    stickyFooterVisibilityTrackingRef,
    advertContactDetails,
    adId,
    taggingData,
    contactOption,
    adStatus,
    doFacebookAndGoogleTracking,
    orgId,
    verticalId,
    onAdvertisingStateContacted,
}) => (
    <AdDetailStickyFooter
        stickyFooterVisibilityTrackingRef={stickyFooterVisibilityTrackingRef}
        buttons={
            <Fragment>
                <StickyFooterPhoneNumberButton
                    adId={adId}
                    taggingData={taggingData}
                    optimizelyTrackEvent="bbx_bottom_sticky_button_call_small"
                    advertContactDetails={advertContactDetails}
                    onAdvertisingStateContacted={onAdvertisingStateContacted}
                />
                <ContactButton
                    size="large"
                    contactType={contactOption.contactType}
                    taggingData={taggingData}
                    scrollLogicalPosition="start"
                    adStatus={adStatus}
                    testId="ad-detail-footer-sticky-message-button"
                    doFacebookAndGoogleTracking={doFacebookAndGoogleTracking}
                    adId={adId}
                    orgId={orgId}
                    css={css`
                        flex: 1 1 56%;
                    `}
                    verticalId={verticalId}
                >
                    Kontaktieren
                </ContactButton>
            </Fragment>
        }
    />
)
