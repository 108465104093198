import React, { FunctionComponent } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { ResponsiveValue } from '@wh-components/system'
import { AttributeGroup, FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'
import { Columns } from '@wh-components/core/Columns/Columns'
import { PropertyItem, PropertyList } from '@bbx/common/components/Lists/PropertyList'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import CheckMarkIcon from '@wh-components/icons/Check'

interface AttributesBoxProps extends SpaceProps, TestProps {
    title?: string
    groupedAttributes: AttributeGroup[]
    columns?: ResponsiveValue<number>
    displayHeading?: ResponsiveValue<string>
}

export const AttributesBox: FunctionComponent<AttributesBoxProps> = ({
    title,
    groupedAttributes,
    columns = { phone: 1, desktop: 2 },
    displayHeading = 'inline',
    ...props
}) => {
    if (groupedAttributes.every((group) => group.attribute.length === 0)) {
        return null
    }

    return (
        <Box {...props}>
            {title && <AdBoxHeading display={displayHeading} text={title} marginBottom="s" />}
            {groupedAttributes.map((attributeGroup) => (
                <AttributeGroupComponent attributeGroup={attributeGroup} key={attributeGroup.id} columns={columns} />
            ))}
        </Box>
    )
}

interface AttributeGroupComponentProps {
    attributeGroup: AttributeGroup
    columns: ResponsiveValue<number>
}

const AttributeGroupComponent: FunctionComponent<AttributeGroupComponentProps> = ({ attributeGroup, columns }) => {
    if (attributeGroup.attribute.length === 0) {
        return null
    }

    return (
        <Box marginTop="s" testId="attribute-group">
            {attributeGroup.heading && <Heading level={3} fontSize="m" text={attributeGroup.heading} marginBottom="xs" />}
            <Columns columns={columns} gap="m">
                <PropertyList>
                    {attributeGroup.attribute.map((attribute) => (
                        <AttributeItem attribute={attribute} key={`${attribute.name}-${attribute.description}`} />
                    ))}
                </PropertyList>
            </Columns>
        </Box>
    )
}

interface AttributeItemProps {
    attribute: FormattedAttributesItem
}

const AttributeItem: FunctionComponent<AttributeItemProps> = ({ attribute }) => (
    <PropertyItem title={attribute.description} testId="attribute">
        {attribute.values[0] === 'Ja' ? (
            <CheckMarkIcon display="block" size="xsmall" color="palette.primary.main" />
        ) : (
            getValueForDisplay(attribute)
        )}
    </PropertyItem>
)

export const getValueForDisplay = (attribute: FormattedAttributesItem) => {
    const formattedValues = attribute.formattedValues
    if (attribute.renderAsArray) {
        return formattedValues.join(', ')
    }

    const value = formattedValues?.[0]
    const prefix = attribute.prefix
    const postfix = getPostfix(attribute)

    return `${prefix ?? ''} ${value} ${postfix ?? ''}`.trim()
}

const getPostfix = (attribute: FormattedAttributesItem) => {
    return attribute.name === 'ENGINE/EFFECT' ? `PS (${attribute.values[0]} kW)` : attribute.postfix
}
