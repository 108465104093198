import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { useMemo } from 'react'
import { getMapCoordinates } from './getMapCoordinates'

export const useMapCoordinates = (advertDetails: AdvertDetails) => {
    const coordinates = useMemo(() => getMapCoordinates(advertDetails), [advertDetails])
    const radius = getAttributeValue(advertDetails.attributes.attribute, 'POSITION_RADIUS_METERS')
    const areAccurate = getAttributeValue(advertDetails.attributes.attribute, 'SHOW_MAP') === 'true'
    const areSuitableForShadowmap = getAttributeValue(advertDetails.attributes.attribute, 'SHOW_SHADOWMAP') === 'true'

    const parsedRadius = radius ? parseInt(radius, 10) : undefined

    return { coordinates: coordinates, radius: parsedRadius, areAccurate, areSuitableForShadowmap }
}
